
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import { AddFaceInfo } from '@/types/blackList'
@Component({
  name: 'FaceCapture',
  components: { UploadFile }
})
export default class extends Vue {
  private searchInfo = {
    projectName: '',
    deviceNumber: '',
    gender: '',
    startTime: '',
    endTime: ''
  }

  private formInfo: AddFaceInfo = {
    blackType: '1',
    blackUserName: '',
    gender: '',
    remark: '',
    resourceList: [],
    faceId: ''
  }

  private info = {
    suggestion: null
  }

  private titleDig = '新增黑名单'
  private devideRules = {
    resourceList: [
      { required: true, message: '请选择照片', trigger: 'change' }
    ]
  }

  private date: string[] = []

  private dataList = []
  private loading = false
  private showInfo = false

  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  get projectList () {
    return this.$store.state.projectList
  }

  getData () {
    this.searchInfo.startTime = this.date[0]
    this.searchInfo.endTime = this.date[1]
    this.loading = true
    this.$axios.get(this.$apis.blackList.selectCameraFaceByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onadd (row: AddFaceInfo) {
    this.showInfo = true
    this.formInfo.faceId = row.faceId
    this.formInfo.resourceList = row.resourceList
  }

  imgRemove (index: number) {
    this.formInfo.resourceList.splice(index, 1)
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formInfo.resourceList.push(file)
    this.$nextTick(() => {
      (this.$refs.InfoForm as ElForm).clearValidate()
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'faceCaptureDetail',
      params: { id: id }
    })
  }

  onSubmit () {
    (this.$refs.InfoForm as ElForm).validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.blackList.insertCameraBlack, this.formInfo)
          .then(() => {
            (this.$refs.InfoForm as ElForm).resetFields()
            this.showInfo = false
            this.$message.success('保存成功')
            this.getData()
          })
      }
    })
  }

  closeDialog () {
    this.showInfo = false
  }
}
